import React, { useState, useEffect } from 'react'
import { Link, graphql } from 'gatsby'
import { useQueryParamString } from 'react-use-query-param-string'
import { useLocation } from '@reach/router'
import parse from 'html-react-parser'
import clsx from 'clsx'
import { useQuizResultsMetadata, heroData } from '@hooks/quiz-results-metadata'
import { getImage } from 'gatsby-plugin-image'

import Seo from '@components/seo/Index'
import LayoutInnerPage from '@src/layouts/InnerPage'
import QuizResultsHero from '@components/pages/quiz-results/Hero'
import QuizOtherOffers from '@components/pages/quiz-results/OtherOffers'
import ReviewsFiveStarWithSlider from '@components/global/Reviews/FiveStarWithSlider'
import SocialConnectCards from '@components/global/cards/SocialConnectCards'

import * as cx from './QuizResults.module.scss'

const QuizResultsTemplate = ({ data: { page }, pageContext: { seo, uri } }) => {
  const query = useQuizResultsMetadata()
  const [productType, setProductType] = useState('placeholder')
  const [gradient, setGradient] = useState('')

  const productTypes = [
    'agentimagex',
    'agentpro',
    'semicustom',
    'imaginestudio',
  ]
  const location = useLocation()
  const search = new URLSearchParams(location.search)
  const [productTypeParam] = useQueryParamString('product-type', productType)

  const gradients = {
    agentimagex: 'bg-gradient-silver-lining',
    agentpro: 'bg-gradient-slider-300',
    semicustom: 'bg-gradient-blue-surf',
    imaginestudio: '',
  }

  useEffect(() => {
    if (search.get('product-type') !== null) {
      setProductType(search.get('product-type'))
    }
    setProductType(
      productTypes.includes(productTypeParam) ? productTypeParam : 'placeholder'
    )
    setGradient(gradients[productType])
  }, [productTypes, search, gradients, productTypeParam])

  return (
    <LayoutInnerPage>
      <Seo title={page.title} seo={seo} uri={uri} />
      <QuizResultsHero
        breadcrumbs={page?.seo?.breadcrumbs}
        data={heroData[productType]}
        image={getImage(query[productType])}
        gradient={gradient}
      />
      <QuizOtherOffers
        background={getImage(query.otherOffersBackground)}
        exceptId={productType}
      />
      <ReviewsFiveStarWithSlider />
      <SocialConnectCards
        // wrapperClassName={cx.social}
        heading="Latest Updates On Social Media"
        subheading="follow us on social"
      />
    </LayoutInnerPage>
  )
}

export default QuizResultsTemplate

export const QuizResultsPageQuery = graphql`
  query QuizResultsPageById($id: String!) {
    page: wpPage(id: { eq: $id }) {
      id
      content
      title
      date(formatString: "MMMM DD, YYYY")

      seo {
        breadcrumbs {
          text
          url
        }
      }
    }
  }

  fragment QuizResultsPageImage on File {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, quality: 90, formats: [AUTO])
    }
  }
`
