// extracted by mini-css-extract-plugin
export var borderGradient = "QuizResultsHero-module--border-gradient--31191";
export var breadcrumbs = "QuizResultsHero-module--breadcrumbs--6cacf";
export var checklist = "QuizResultsHero-module--checklist--25a49";
export var contacts = "QuizResultsHero-module--contacts--bcf6f";
export var container = "QuizResultsHero-module--container--4d7a1";
export var content = "QuizResultsHero-module--content--4e6fb";
export var description = "QuizResultsHero-module--description--f4152";
export var heading = "QuizResultsHero-module--heading--fcd3e";
export var image = "QuizResultsHero-module--image--f171d";
export var productFeatures = "QuizResultsHero-module--product-features--b1506";
export var smallHeading = "QuizResultsHero-module--small-heading--3de10";
export var subHeading = "QuizResultsHero-module--sub-heading--e718c";
export var subLink = "QuizResultsHero-module--sub-link--08a18";
export var wrapper = "QuizResultsHero-module--wrapper--e8abf";