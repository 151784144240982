import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

export const useQuizResultsMetadata = () => {
  const query = useStaticQuery(graphql`
    query QuizResultsMetaData {
      agentimagex: file(relativePath: { eq: "quiz-results/AgentImageX.png" }) {
        id
        ...QuizResultsImageQuality
      }
      agentpro: file(relativePath: { eq: "quiz-results/AgentPro.png" }) {
        id
        ...QuizResultsImageQuality
      }
      semicustom: file(relativePath: { eq: "quiz-results/SemiCustom.png" }) {
        id
        ...QuizResultsImageQuality
      }
      imaginestudio: file(
        relativePath: { eq: "quiz-results/ImagineStudio.png" }
      ) {
        id
        ...QuizResultsImageQuality
      }
      otherOffersBackground: file(
        relativePath: { eq: "quiz-results/other-offers/background.jpg" }
      ) {
        id
        ...QuizResultsImageQuality
      }
    }

    fragment QuizResultsImageQuality on File {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, quality: 90, formats: [AUTO])
      }
    }
  `)

  return query
}

export const heroData = {
  placeholder: {
    gradient: null,
    title: '...',
    description: '...',
    features: [
      {
        content: '...',
        check: true,
      },
      {
        content: '...',
        check: true,
      },
    ],
    button: {
      label: '...',
      url: '',
    },
  },
  agentimagex: {
    gradient: 'silver-lining',
    title: 'Agent Image X',
    description:
      'A theme-based package whose upscale design options look anything but entry-level.',
    features: [
      {
        content:
          'Choose from eight upscale templates that include a pre-designed home page and inner page layout',
        check: true,
      },
      {
        content: 'IDX compatible with iHomeFinder or IDX Broker™',
        check: true,
      },
    ],
    button: {
      label: 'Learn More',
      url: '/real-estate-websites/agent-image-x/',
    },
  },
  agentpro: {
    gradient: 'slider-300',
    title: 'Agent Pro',
    description:
      'Get access to a broader selection of beautiful templates that come with useful features straight out of the box.',
    features: [
      {
        content:
          'Choose from sixteen upscale templates that include a pre-designed home page and inner page layout',
        check: true,
      },
      {
        content:
          'Ability to create and add an unlimited number of pages to the website',
        check: true,
      },
    ],
    button: {
      label: 'Learn More',
      url: '/real-estate-websites/agent-pro/',
    },
  },
  semicustom: {
    gradient: 'blue-surf',
    title: 'Semi-Custom',
    description:
      "Our most popular product. Get your very own custom branded homepage and we'll match inner pages to fit your branding.",
    features: [
      {
        content:
          'Custom designed home page and inner page design that positions you to stand out online',
        check: true,
      },
      {
        content: 'IDX search integration',
        check: true,
      },
    ],
    button: {
      label: 'Learn More',
      url: '/real-estate-websites/semi-custom/',
    },
  },
  imaginestudio: {
    gradient: null,
    title: 'Imagine Studio',
    description:
      'The Imagine Studio package is for real estate agents and brokerages who require a higher level of custom design.',
    features: [
      {
        content: 'A custom designed home page and three inner page designs',
        check: true,
      },
      {
        content: 'One custom designed map',
        check: true,
      },
    ],
    button: {
      label: 'Learn More',
      url: '/real-estate-websites/imagine-studio/',
    },
  },
}
