import React from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import clsx from 'clsx'
import { getImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
// import { extractGatsbyImageDataRelativePath } from '@utils/imageFile'

import {
  useHomePageStaticData,
  QuizComparisonItems,
} from '@hooks/home-page-static'

import ElementImageFile from '@components/global/element/ImageFile'

import * as cx from './OtherOffers.module.scss'

const QuizOtherOffers = ({ background, exceptId = 'semicustom' }) => {
  const data = useHomePageStaticData()

  return (
    <section id="quiz-other-offers" className={clsx(cx.wrapper)}>
      <ElementImageFile
        src={background}
        alt="Quiz Other Offers Background"
        lazy={false}
        className="image-background"
      />

      <div className={clsx(cx.container)}>
        <h2 className={clsx('subtitle-1', cx.heading)}>
          Explore Our Other Offers
        </h2>

        <div className={clsx(cx.items)}>
          {QuizComparisonItems.map((item, index) => {
            if (item.id !== exceptId) {
              return (
                <div key={item.id} className={clsx(cx.item)}>
                  <ElementImageFile
                    src={getImage(data[item.id])}
                    alt={item.title}
                  />
                  <div className={clsx(cx.itemContainer)}>
                    <div className={clsx(cx.content)}>
                      <h3 className="subtitle-2">{item.title}</h3>
                      <p className="default-body large">{item.description}</p>
                      <div
                        className={clsx(
                          cx.borderGradient,
                          item.gradient !== null &&
                            `bg-gradient-${item.gradient}`
                        )}
                      ></div>
                    </div>
                  </div>
                </div>
              )
            }
          })}
        </div>

        <Link
          to="/choose-a-website/"
          className={clsx('primary-button', 'large', cx.btn)}
        >
          View Full Comparison Chart
        </Link>
      </div>
    </section>
  )
}

QuizOtherOffers.propTypes = {
  background: PropTypes.object,
  exceptId: PropTypes.string,
}

export default QuizOtherOffers
