import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import clsx from 'clsx'
import useMedia, { media } from '@hooks/useMedia'
import { removeElementTags } from '@utils/removeElementTags'

import Breadcrumbs from '@components/seo/Breadcrumbs'
import CheckItems from '@components/global/CheckItems'
import ElementImageFile from '@components/global/element/ImageFile'
import ModalForm from '@components/global/modal/Form'
import FormSignUp from '@components/global/forms/SignUp'

import * as cx from './QuizResultsHero.module.scss'

import SvgStar from '@src/svg/star.inline'

const QuizResultsHero = ({ breadcrumbs, data, image, gradient = '' }) => {
  const modalRef = useRef()
  const desktop = useMedia(media.desktop)
  const tablet = useMedia(media.tablet)
  const phone = useMedia(media.phone)
  const [isDesktop, setIsDesktop] = useState(false)
  const [isTablet, setIsTablet] = useState(false)
  const [isPhone, setIsPhone] = useState(false)

  useEffect(() => {
    setIsDesktop(desktop)
    setIsTablet(tablet)
    setIsPhone(phone)
  }, [tablet, phone])

  return (
    <section id="hero" className={clsx(cx.wrapper)}>
      <ModalForm
        childFunc={modalRef}
        desktopTitle="Ready To Build An <br>Award-Winning Website?"
        mobileTitle="Ready To Build <br />An Award-Winning <br />Website?"
        template={'default'}
      >
        <FormSignUp
          submitLabel="Unlock next-level growth!"
          labelClassName="desktop-only"
          leadName={`Results Page: ${removeElementTags(data.title)}`}
        />
      </ModalForm>
      <div className={clsx(cx.borderGradient, gradient)}></div>
      <div className={clsx(cx.breadcrumbs, 'container')}>
        <Breadcrumbs wrapperClassName="global-breadcrumbs" data={breadcrumbs} />
      </div>
      <div className={clsx(cx.container)}>
        <div className={clsx(cx.heading)}>
          <p className="subtitle-7">We Think You’ll Love</p>
          <h1 className="heading-1">{parse(data.title)}</h1>
        </div>
        <div className={clsx(cx.content)}>
          <div className={clsx(cx.image)}>
            {image && (
              <ElementImageFile
                src={image}
                alt={removeElementTags(data.title)}
                lazy={false}
              />
            )}
          </div>
          <div className={clsx(cx.description)}>
            <div className={clsx(cx.subHeading)}>
              {removeElementTags(data.title) === 'Semi-Custom' && (
                <div className={clsx('badge')}>
                  Most Popular <SvgStar />
                </div>
              )}
              <p className={clsx('subtitle-7', cx.productFeatures)}>
                Product Features <br />
                at a Glance
              </p>
            </div>
            <p className={clsx('subtitle-5')}>{data.description}</p>
            <p className={clsx('small-heading', 'strong', cx.smallHeading)}>
              Key Features
            </p>
            <CheckItems
              itemClass={clsx('subtitle-5')}
              items={data.features}
              parentClass={clsx(cx.checklist)}
              hasLink={true}
              buttonClass={clsx('primary-button large alt', cx.subLink)}
              buttonText={data.button.label}
              buttonUrl={data.button.url}
            />
          </div>
        </div>
        <div className={clsx(cx.contacts)}>
          <h3
            className={clsx(
              isDesktop && 'subtitle-3',
              isTablet && 'subtitle-5'
            )}
          >
            Get a FREE consultation with our Web Marketing Strategists
          </h3>
          <button
            type="button"
            className="primary-button large"
            onClick={(e) => modalRef.current.openModal()}
          >
            Book Your Consultation
          </button>
          <a href="tel:+1.800.979.5799" className="subtitle-3">
            Or Call 800.979.5799
          </a>
        </div>
      </div>
    </section>
  )
}

QuizResultsHero.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
  data: PropTypes.object,
  gradient: PropTypes.string,
  image: PropTypes.object, // Temporary
}

export default QuizResultsHero
